<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-08 16:14:24
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-22 16:07:48
 * @FilePath: \official_website\src\mobile\pages\medium\Medium.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="medium">
    <Header></Header>
    <div class="medium-box">
      <div class="medium-header">
        <h2 class="title wow animate__animated animate__fadeInUp">新闻资讯</h2>
        <div class="letters wow animate__animated animate__fadeInUp">News</div>
      </div>
      <ul class="news-box">
        <li
          class="news-item"
          v-for="(item, index) in newsList"
          :key="index"
          @click="tapArticle(item.id)"
        >
          <img class="news-img" :src="item.titleImage" />
          <div class="news-text wow animate__animated animate__fadeInUp">{{ item.title }}</div>
          <div class="times wow animate__animated animate__fadeInUp">{{ item.createTime }}</div>
        </li>
      </ul>
      <div class="news-footer">
        <van-button plain color="#0b847b" v-if="moreFlag" @click="knowMore">了解更多</van-button>
      </div>
      <div class="backTop" v-show="backTopFlag" @click="backTop">
        <i class="iconfont icon-huidaodingbu"></i>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      newsList: [], // 分页新闻列表
      count: 0, // 新闻列表数量
      currentPage: 1, // 当前页码
      moreFlag: true,
      backTopFlag: false,
    };
  },
  created() {
    this.getNewsList();
    this.getNewsCounts();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });

    // window.addEventListener("scroll", this.handleScroll, true);
  },
  watch: {
     currentPage: {
      handler(oldValue, newValue) {
        // console.log(newValue);
        setTimeout(() => {
          if (this.currentPage >= Math.ceil(this.count / 10)) {
            this.moreFlag = false;
          }
        }, 300);
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 分页获取文章列表
    getNewsList(page = 1) {
      api
        .getNewsByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            result.forEach((item) => {
              if (item.createTime) {
                item.createTime = item.createTime.split(" ")[0];
              } else {
                item.createTime = "";
              }
            });

            if(page > 1) {
              this.newsList = this.newsList.concat(result)
            } else {
              this.newsList = result;
            }
            
            // console.log(this.newsList);
            // this.$nextTick(() => {
            //   new this.$wow.WOW({
            //     boxClass: "wow", // 动画元件css类（默认为wow）
            //     animateClass: "animate__animated", //动画css类（默认为animated）
            //     offset: 0, //到元素距离触发动画（当默认为0）
            //     mobile: true, //在移动设备上触发动画（默认为true）
            //     live: true, //对异步加载的内容进行操作（默认为true）
            //   }).init();
            // });
          }
        })
        .catch((error) => {});
    },
    // 获取文章总条数
    getNewsCounts() {
      api
        .getNewsCount()
        .then((res) => {
          if (res.data.code == 200) {
            this.count = res.data.models[0];
          }
        })
        .catch((error) => {});
    },
    tapArticle(id) {
      this.$router.push({
        path: `/MediumDetail/${id}`,
      });
    },
    knowMore() {
      if(this.currentPage < Math.ceil(this.count / 10)) {
        this.currentPage++
        this.getNewsList(this.currentPage);
      }
    },
     // 回到顶部
    backTop() {
      this.$nextTick(() => {
        document.getElementById("medium").scrollTop = 0;
      });
    },
    handleScroll(env) {
      this.$nextTick(() => {
        let scrollTop = document.getElementById("medium").scrollTop;
        console.log(scrollTop);
        if (scrollTop > 200) {
          this.backTopFlag = true;
        } else {
          this.backTopFlag = false;
        }
      });
    },
  },
};
</script>
    
<style lang="scss">
#medium {
  padding-top: 3.75rem;
  .medium-box {
    .medium-header {
      padding: 1.5rem 1.25rem;
      .line {
      }
      .title {
        position: relative;
        padding-top: 1.5rem;
        margin-bottom: 0.5rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 4.5rem;
          height: 0.625rem;
          background-color: #0b847b;
          -webkit-transition: width 0.5s ease-out 0.8s;
          transition: width 0.5s ease-out 0.8s;
        }
      }
      .letters {
        font-size: 1.2rem;
        color: #999999;
        font-family: "OPlusSans3-Regular";
      }
    }
    .news-box {
      padding: 0 1.25rem;
      .news-item {
        padding: 1rem 0;
        border-bottom: 1px solid #ddd;
        .news-img {
          width: 100%;
        }
        .news-text {
          padding: 0.8rem 0.5rem;
          font-size: 1rem;
          font-family: "SourceHanSansSC-Medium";
          background-color: #fafafa;
        }
        .times {
          color: #666666;
          padding: 0.5rem;
          font-family: "OPlusSans3-Regular";
          font-size: 0.75rem;
          background-color: #fafafa;
        }
      }
    }
    .news-footer {
      display: flex;
      justify-content: center;
      padding: 1rem 0 2rem;
    }
    .backTop {
    width: 2.25px;
    height: 2.25px;
    background-color: #666;
    border-radius: 50%;
    opacity: 0.8;
    position: fixed;
    right: 1rem;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.25rem;
      color: #fff;
    }
  }
  }

  .footer {
    background: #f5f5f5;
  }
}
</style>